@if ($loading()) {
  <ev-loading-overlay></ev-loading-overlay>
}

<form [formGroup]="orderInfoFormGroup" (ngSubmit)="onSubmit()">
  <div class="blocks flex flex-col gap-6 mt-6">
    <!-- UserInfo block -->
    <div class="block flex flex-col gap-2" formGroupName="userInfo">
      <div class="block-title">Personal Information</div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.userInfo.name)">
        <div class="ev-input-label">{{ placeHolderTexts.userInfo.name }}*</div>
        <input type="text" class="ev-input w-full" formControlName="name" />
      </div>

      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.userInfo.email)">
        <div class="ev-input-label">{{ placeHolderTexts.userInfo.email }}*</div>
        <input type="text" class="ev-input w-full" formControlName="email" />
        @if (controls.userInfo.email.invalid) {
          <div class="ev-input-error">This must be a valid email address.</div>
        }
      </div>

      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.userInfo.phoneNumber)">
        <div class="ev-input-label">
          <span>{{ placeHolderTexts.userInfo.phoneNumber }}</span>
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="phoneNumber" />
        @if (controls.userInfo.phoneNumber.invalid) {
          <div class="ev-input-error">
            If no + sign preceeds the numeric value, the service will assume its
            an indian number by default (expects exactly 10 characters long
            number). If an international number is to be entered, please add a +
            sign before the number.
          </div>
        }
      </div>
    </div>

    <div class="block flex flex-col gap-2" formGroupName="shippingAddress">
      <div class="block-title">Shipping Address</div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.shippingAddress.zipCode)">
        <div class="ev-input-label">
          {{ placeHolderTexts.shippingAddress.zipCode }}*
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="zipCode"
          [ngClass]="{
            invalid:
              controls.shippingAddress.zipCode.invalid &&
              controls.shippingAddress.zipCode.touched,
          }" />
      </div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.shippingAddress.city)">
        <div class="ev-input-label">
          {{ placeHolderTexts.shippingAddress.city }}*
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="city"
          [ngClass]="{
            invalid:
              controls.shippingAddress.city.invalid &&
              controls.shippingAddress.city.touched,
          }" />
      </div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.shippingAddress.street)">
        <div class="ev-input-label">
          {{ placeHolderTexts.shippingAddress.street }}*
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="street"
          [ngClass]="{
            invalid:
              controls.shippingAddress.street.invalid &&
              controls.shippingAddress.street.touched,
          }" />
      </div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.shippingAddress.additionalInfo)">
        <div class="ev-input-label">
          {{ placeHolderTexts.shippingAddress.additionalInfo }}
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="additionalInfo"
          [ngClass]="{
            invalid:
              controls.shippingAddress.additionalInfo.invalid &&
              controls.shippingAddress.additionalInfo.touched,
          }" />
      </div>

      <atom-checkbox
        [label]="sameBillingAddressLabel"
        [choiceValue]="true"
        [checked]="sameBillingAddress"
        (change)="onSameBillingAddressChange($event)"></atom-checkbox>
    </div>

    @if (!sameBillingAddress) {
      <div class="block flex flex-col gap-2" formGroupName="billingAddress">
        <div class="block-title">Billing Address</div>
        <div
          class="input-wrapper"
          [evInvalid]="invalidCheck(controls.billingAddress.zipCode)">
          <div class="ev-input-label">
            {{ placeHolderTexts.billingAddress.zipCode }}*
          </div>
          <input
            type="text"
            class="ev-input w-full"
            formControlName="zipCode" />
        </div>
        <div
          class="input-wrapper"
          [evInvalid]="invalidCheck(controls.billingAddress.city)">
          <div class="ev-input-label">
            {{ placeHolderTexts.billingAddress.city }}*
          </div>
          <input type="text" class="ev-input w-full" formControlName="city" />
        </div>
        <div
          class="input-wrapper"
          [evInvalid]="invalidCheck(controls.billingAddress.street)">
          <div class="ev-input-label">
            {{ placeHolderTexts.billingAddress.street }}*
          </div>
          <input type="text" class="ev-input w-full" formControlName="street" />
        </div>
        <div
          class="input-wrapper"
          [evInvalid]="invalidCheck(controls.billingAddress.additionalInfo)">
          <div class="ev-input-label">
            {{ placeHolderTexts.billingAddress.additionalInfo }}
          </div>
          <input
            type="text"
            class="ev-input w-full"
            formControlName="additionalInfo" />
        </div>
      </div>
    }

    <div class="block flex flex-col gap-2" formGroupName="userInfo">
      <div class="block-title">Additional Information</div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.userInfo.gstNumber)">
        <div class="ev-input-label">
          {{ placeHolderTexts.userInfo.gstNumber }}*
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="gstNumber" />
      </div>
      <div
        class="input-wrapper"
        [evInvalid]="invalidCheck(controls.userInfo.panNumber)">
        <div class="ev-input-label">
          {{ placeHolderTexts.userInfo.panNumber }}*
        </div>
        <input
          type="text"
          class="ev-input w-full"
          formControlName="panNumber" />
      </div>
    </div>
  </div>

  <div
    class="actions-buttons mt-8 flex flex-wrap gap-4 justify-between max-sm:flex-col-reverse">
    <atom-button
      class="left-block"
      secondary
      (click)="purchaseService.back()"
      data-cy="continue-shopping-button">
      <span>Back to cart</span>
    </atom-button>

    <atom-button
      class="right-block"
      (click)="onSubmit()"
      [disabled]="orderInfoFormGroup.invalid">
      <span>Proceed to checkout</span>
    </atom-button>
  </div>
</form>
