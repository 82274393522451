@if (cart$ | async; as cart) {
  <ev-progress-steps class="mb-4"></ev-progress-steps>
  @if (cart.lineItems.length) {
    <div class="stepper-container flex justify-center mb-4">
      <atom-stepper
        [count]="3"
        [current]="$currentStep() + 1"
        (stepChange)="onStepChange($event)"></atom-stepper>
    </div>
  }

  <div class="highlighted-container p-2 sm:p-8">
    <nav class="flex justify-between">
      <div class="title">Shopping Cart</div>
    </nav>
    <hr class="mt-4 mb-6" />
    @switch ($currentStep()) {
      @case (Step.Cart) {
        @if (cart.lineItems.length) {
          <dp-cart [cart]="cart"></dp-cart>
        } @else {
          <dp-empty-cart (continueShopping)="onExit()"></dp-empty-cart>
        }
      }
      @case (Step.OrderInfo) {
        <dp-order-info></dp-order-info>
      }
      @case (Step.Review) {
        <dp-review [cart]="cart"></dp-review>
      }
    }
  </div>
} @else {
  <atom-progress-spinner></atom-progress-spinner>
}
