<div class="table-header-container hidden xl:grid">
  <div evTableHeader class="table-header">
    <div class="col title"></div>
    <div class="col unit-price">Price incl. GST</div>
    <div class="col package-size">Package Size</div>
    <div class="col quantity">Quantity</div>
    <div class="col total-amount">Total Quantity</div>
    <div class="col delivery-date">Requested Pick Up Date</div>
    <div class="col total-price">Total Price incl. GST</div>
  </div>
</div>
@for (
  lineItemControl of cartFormGroup.controls.lineItems.controls;
  track i;
  let i = $index
) {
  @if (cart.lineItems[i]; as lineItem) {
    <dp-line-item
      data-cy="line-item"
      class="ev-row"
      [cartItem]="lineItem"
      [formGroup]="lineItemControl"
      [readOnly]="readOnly"
      (removeItem)="
        onRemoveItem(lineItem.id, lineItem.quantity)
      "></dp-line-item>
  }
}

<div class="sum-price-container mt-8 mb-8 flex justify-end">
  <div class="sum-price-grid grid grid-cols-2 gap-4 justify-items-end mr-6">
    <div class="label font-bold">Total price incl. GST</div>
    <div class="value font-bold">
      {{ totalGrossPrice | evPrice }}
    </div>

    <!-- TODO: figure out the taxes!!! -->
    <!-- <div class="label font-bold">Total price</div>
    <div class="value font-bold">
      {{ cart.totalGrossPrice | evPrice }}
    </div> -->
  </div>
</div>

<div class="feedback-messages">
  <!-- Render warnings and errors -->
  @for (warningMessage of cart.warnings; track $index) {
    <ev-notification class="mt-4" type="warning">
      {{ warningMessage }}
    </ev-notification>
  }
  @for (errorMessage of cart.errors; track $index) {
    <ev-notification class="mt-4" type="error">
      {{ errorMessage }}
    </ev-notification>
  }
</div>
