<div class="line-item p-2 sm:p-6">
  <div class="header-block xl:hidden">
    <div class="title-bar flex items-center justify-between">
      <div
        class="title ev-link"
        [routerLink]="['/products', cartItem.product.id]">
        {{ cartItem.product.name }}
      </div>
      @if (!readOnly) {
        <atom-icon
          icon-id="atom:basf:trash"
          class="trash-icon cursor-pointer select-none"
          (click)="onRemoveItem()"></atom-icon>
      }
    </div>
    <hr class="my-4" />
  </div>
  <div class="line-item-grid">
    <div class="col title hidden xl:block">
      {{ cartItem.product.name }}
    </div>

    <div class="col unit-price">
      <div class="label xl:hidden">Price incl. GST</div>
      <div class="value">
        {{ cartItem.article.grossPricePerUnit | evPrice }} /
        {{ cartItem.article.packagingSize.unitOfMeasurement }}</div
      >
    </div>

    <div class="col package-size">
      <div class="label xl:hidden">Package Size</div>
      <div class="value">
        {{ cartItem.article.packagingSize.value | number: "1.2-2" }}
        {{ cartItem.article.packagingSize.unitOfMeasurement }}
      </div>
    </div>

    <div class="col quantity">
      <div class="label xl:hidden">Quantity</div>
      <div class="value">
        @if (!readOnly) {
          <ev-quantity-selector
            size="small"
            [quantityFormControl]="
              formGroup.controls.quantity
            "></ev-quantity-selector>
        } @else {
          {{ cartItem.quantity }}
        }
      </div>
    </div>

    <div class="col total-amount">
      <div class="label xl:hidden">Total Quantity</div>
      <div class="value">
        {{ totalAmount() }}
        {{ cartItem.article.packagingSize.unitOfMeasurement }}
      </div>
    </div>

    <div class="col delivery-date">
      <div class="label xl:hidden">Requested Pick Up Date</div>
      <div class="value">
        @if (!readOnly) {
          <input
            type="date"
            class="ev-input small"
            [min]="tomorrowDate"
            [ngClass]="{ invalid: formGroup.controls.deliveryDate.invalid }"
            [formControl]="formGroup.controls.deliveryDate" />
        } @else {
          {{ cartItem.requestedDeliveryDate | date }}
        }
      </div>
    </div>
    <div class="col total-price">
      <div class="label xl:hidden">Total Price incl. GST</div>
      <div class="value">
        {{
          {
            value:
              cartItem.article.grossPrice.value *
              (formGroup.controls.quantity.value ?? 0),
            currency: cartItem.article.grossPrice.currency,
          } | evPrice
        }}
      </div>
    </div>

    @if (!readOnly) {
      <div class="col remove hidden xl:block">
        <atom-icon
          icon-id="atom:basf:trash"
          class="trash-icon cursor-pointer select-none"
          (click)="onRemoveItem()"></atom-icon>
      </div>
    }
  </div>

  <!-- Render warnings and errors -->
  @for (warningMessage of cartItem.warnings; track $index) {
    <ev-notification class="mt-4" type="warning">
      {{ warningMessage }}
    </ev-notification>
  }
  @for (errorMessage of cartItem.errors; track $index) {
    <ev-notification class="mt-4" type="error">
      {{ errorMessage }}
    </ev-notification>
  }
</div>
